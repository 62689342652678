import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ToastMsg = ({ msg, msgtop, msgPop, severity, testId }) => {
    const [toastOpen, setToastOpen] = useState(true);
    const vertical = msgtop ? 'top' : 'bottom';
    const horizontal = msgtop ? 'center' : 'left';

    useEffect(() => {
        const timer = setTimeout(() => {
            msgPop();
        }, 6000);

        return () => {
            clearTimeout(timer);
        };
    }, [msgPop]);

    const handleClose = () => {
        setToastOpen(false);
    };

    return (
        <div>
            <Snackbar open={toastOpen} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
                <Alert invoice_number={testId} emed_tid={'toast'} onClose={handleClose} variant="filled" severity={severity} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
        </div>
    );
};

const setCachevalue = (value, key) => {
    sessionStorage.setItem(key,value);
}
const getCachevalue = (key) => {
    let value = sessionStorage.getItem(key);
    return value;
}
const clearCachevalue = (key) => {
    sessionStorage.removeItem(key);
}
const clearAllCachevalue = () => {
    sessionStorage.clear();
}
const localSetItem = (key, value) =>{
    return localStorage.setItem(key, value);
}
const localGetItem = (key) =>{
    return localStorage.getItem(key);
}
const localClearItem = (key) => {
    return localStorage.removeItem(key);
}
const localClearAll = () =>{
    return localStorage.clear();
}
export { ToastMsg, setCachevalue, getCachevalue, clearCachevalue, clearAllCachevalue,localSetItem,localGetItem,localClearItem,localClearAll }