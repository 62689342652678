import React from 'react';
import './Page.css';
import Background from '../../Assets/Images/Background.jpg';

const PageNotFound = () => {
    return (
        <div style={{ backgroundImage: `url(${Background})`, height: '70vh' }}>
            <h1 className='errorpage'>404 Page Not Found</h1>
        </div>
    );
}

export default PageNotFound;