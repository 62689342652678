import ImagePaths from '../../Utility/ImagePaths';
import './Home.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ImagePaths.Logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default Home;