import { Colors } from '../../Utility/Colors'
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation, useNavigate } from 'react-router-dom';
import { QMS_TOKEN_WEBSOCKET, WEB_SOCKET_DOMAIN } from '../../Utility/Serviceurls';

const QmsTokenScreen = () => {
    const location = useLocation();
    const { channelName, clinicName } = location.state ? location.state : {};
    const [TokenList, setTokenList] = useState([]);
    const [time, setTime] = useState(new Date());
    const [speakFlag, setSpeakFlag] = useState(false)
    const navigate = useNavigate();
    const TokenSocket = useRef(null); // Use useRef to store WebSocket

    useEffect(() => {
        // Initialize WebSocket connection
        TokenSocket.current = new WebSocket(`${WEB_SOCKET_DOMAIN}${QMS_TOKEN_WEBSOCKET}${channelName}/`);
        websocketConnection();

        // Update time every minute
        const interval = setInterval(() => {
            setTime(new Date());
        }, 60000);

        // Clean up on component unmount
        return () => {
            if (TokenSocket.current) {
                TokenSocket.current.close();
            }
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (TokenList?.length > 0 && speakFlag) {
            speak();
            setSpeakFlag(false)
        }
    }, [speakFlag])

    const websocketConnection = () => {
        const socket = TokenSocket.current;
        if (socket) {
            socket.onopen = () => {
                console.log('WebSocket connected');
            };
            socket.onclose = () => {
                console.log('WebSocket disconnected');
            };
            socket.onerror = (error) => {
                console.error('WebSocket Error', error);
                socket.close();
            };
            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                setTokenList(prevList => {
                    const newList = [...prevList];
                    if (data?.remove_message_id) {
                        let Array = newList.filter((item) => (item?.message_id !== data?.remove_message_id))
                        return Array;

                    } else {
                        if (newList.length >= 4) {
                            newList.pop();
                        }
                        newList.unshift(data);
                        return newList;
                    }
                });
                if (!data?.remove_message_id) {
                    setSpeakFlag(true)
                }
                console.log('WebSocket Message', data);
            };
        }
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const renderBackScreen = () => {
        navigate(-1);
    };

    const speak = () => {
        if ('speechSynthesis' in window) {
            let text = (TokenList[0]?.token_no ? ` Token Number ${TokenList[0]?.token_no}` : "") + ". " + (TokenList[0]?.room_no ? `Room Number ${TokenList[0]?.room_no}` : "") + ". ";
            const speech = new SpeechSynthesisUtterance(text);
            speech.lang = 'en-IN'; // Set the language to English (India)

            speech.rate = 0.8
            speech.pitch = 1.2

            // Get the list of available voices
            const voices = window.speechSynthesis.getVoices();

            // Select a female voice, or fallback to the first available voice
            const femaleVoice = voices.find(voice =>
                voice.name.toLowerCase().includes('female') ||
                voice.name.toLowerCase().includes('woman') ||
                voice.gender === 'female'
            );

            if (femaleVoice) {
                speech.voice = femaleVoice;
            } else if (voices.length > 0) {
                speech.voice = voices[0]; // fallback to the first available voice
            }

            // Speak the text
            window.speechSynthesis.speak(speech);
        } else {
            alert('Sorry, your browser does not support text-to-speech.');
        }
    };


    return (
        <div style={{ margin: '1vw' }}>
            <Box sx={{ backgroundColor: '#04B7B1', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1vw' }}>
                <IconButton sx={{ width: '2vw' }} onClick={renderBackScreen}>
                    <ArrowBackIosNewIcon style={{ color: 'white' }} />
                </IconButton>
                <Box sx={{ fontSize: '3rem', color: 'white', fontWeight: 700 }}>{clinicName}</Box>
                <Box sx={{ fontSize: '3rem', color: 'white', fontWeight: 700 }}>
                    {formatTime(time)}
                </Box>
            </Box>
            <TableContainer sx={{ border: '1px solid lightgray', height: '80vh', backgroundColor: 'white', marginTop: '1vw' }}>
                <Table size='small' sx={{ overflowY: 'scroll' }}>
                    <TableHead>
                        <TableRow sx={{ height: '12vh', backgroundColor: Colors.ThemeLightColor }}>
                            <TableCell align='center' sx={{ fontWeight: '600', borderRight: '1px solid lightgray', fontSize: '2.5vw' }}>
                                <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>Token</Box>
                            </TableCell>
                            <TableCell align='center' sx={{ fontWeight: '600', borderRight: '1px solid lightgray', fontSize: '2.5vw' }}>
                                <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>Room</Box>
                            </TableCell>
                            <TableCell align='center' sx={{ fontWeight: '600', borderRight: '1px solid lightgray', fontSize: '2.5vw', fontWeight: 800 }}>
                                <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>Patient Name</Box>
                            </TableCell>
                            <TableCell align='center' sx={{ fontWeight: '600', borderRight: '1px solid lightgray', fontSize: '2.5vw' }}>
                                <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>Doctor Name</Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {TokenList.length > 0 && TokenList.map((list, index) => (
                            <TableRow key={index} sx={{ height: '12vh' }}>
                                <TableCell align='center' sx={{ borderRight: '1px solid lightgray', fontSize: '2.5vw', font: 'caption' }}>
                                    <Box sx={{ fontSize: '3.5vw', fontWeight: 800, color: 'red' }}>{list?.token_no}</Box>
                                </TableCell>
                                <TableCell align='center' sx={{ borderRight: '1px solid lightgray', fontSize: '2.5vw', font: 'caption' }}>
                                    <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>{list?.room_no}</Box>
                                </TableCell>
                                <TableCell align='center' sx={{ borderRight: '1px solid lightgray' }}>
                                    <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>{list?.patient_name}</Box>
                                </TableCell>
                                <TableCell align='center' sx={{ borderRight: '1px solid lightgray', fontSize: '2.5vw', font: 'caption' }}>
                                    <Box sx={{ fontSize: '2.5vw', fontWeight: 600 }}>{list?.doctor_name}</Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default QmsTokenScreen;
