import { Box, Button, TextField, Typography, IconButton, InputAdornment} from '@mui/material'
import React, { useState } from 'react'
import './Login.css'
import ImagePaths from '../../Utility/ImagePaths'
import { Colors } from '../../Utility/Colors'
import { post } from '../../Utility/RestAPIService'
import { localSetItem, ToastMsg } from '../CommonFunctions/CommonFuntions'
import { API_SIGN_IN } from '../../Utility/Serviceurls'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const QMSLogin = () => {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState("")
    const [isLoader, setIsLoader] = useState(false)
    const [usernameHelperText, setUsernameHelperText] = useState("")
    const [passwordHelperText, setPasswordHelperText] = useState("")
    const [isErrorMsg, setIsErrorMsg] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [isErrorMsgText, setIsErrorMsgText] = useState('');
    const [successMsgText, setsuccessMsgText] = useState('');
    const navigate = useNavigate();

    const Submit = () => {
        try {
            if (userName && password) {
                setIsLoader(true)
                let data = {
                    user_name: userName,
                    password: password
                }
                post(API_SIGN_IN, data)
                    .then(response => {
                        if (response.data.status === "success") {
                            let data = response.data;
                            localSetItem("LogedUserName", data.data.user_name);
                            localSetItem("isLoggedin", true)
                            localSetItem("AccessToken", data.data.token);
                            setIsLoader(false)
                            successMessage(data.message)
                            navigate("/TokenScreen/LinkList")
                        }

                    }).catch((error) => {
                        if (error?.response?.data?.status === 'fail') {
                            errorMessage(error.response.data.message)
                        } else {
                            errorMessage(error.message)
                        }
                        setIsLoader(false)
                    })

            } else {
                localSetItem("isLoggedin", false);
                if (userName === "") {
                    setUsernameHelperText("Enter valid username")
                } else {
                    setUsernameHelperText("")
                }
                if (password === "") {
                    setPasswordHelperText('Enter valid password')
                } else {
                    setPasswordHelperText("")
                }
            }
        }
        catch (e) {
            errorMessage(e?.message || "An unexpected error occurred")
        }
    };

    const errorMessage = (message) => {
        setIsErrorMsg(true)
        setIsErrorMsgText(message)
    }

    const successMessage = (message) => {
        setsuccessMsg(true)
        setsuccessMsgText(message)
    }

    const msgClose = () => {
        setIsErrorMsg(false)
        setIsErrorMsgText('')
        setsuccessMsg(false)
        setsuccessMsgText('')
    }


    return (
        <Box className='eMed_Login_Main_Box'>
            <Box flex={0.4} className="eMed_Login_Left_Box">
                <img src={ImagePaths.LoinLeftImg.default} className="emd_image" alt="Login Left" />
            </Box>
            <Box flex={0.6} className='eMed_Login_Right_Box'>
                <Box className='eMed_Login_Right_Text'>
                    <Typography variant="h2" style={{ color: Colors.ThemeColor }}>eMedHub</Typography>
                </Box>
                <Box className='eMed_Login_Right_Container'>
                    <TextField
                        focused
                        size='small'
                        error={usernameHelperText !== ""}
                        helperText={usernameHelperText}
                        name="username"
                        inputProps={{ 'data-testid': 'username' }}
                        value={userName}
                        sx={{ margin: '2vw 1vw 1vw 1vw', width: '19vw' }}
                        label="Username"
                        onChange={(e) => { 
                            setUserName(e.target.value)
                            setUsernameHelperText("")
                        }}
                    />
                    <TextField
                        focused
                        type={showPassword ? "text" : "password"}
                        size='small'
                        error={passwordHelperText !== ""}
                        helperText={passwordHelperText}
                        name="password"
                        inputProps={{ 'data-testid': 'password' }}
                        value={password}
                        sx={{ margin: '2vw 1vw 1vw 1vw', width: '19vw' }}
                        label="Password"
                        onChange={(e) => { 
                            setPassword(e.target.value)
                            setPasswordHelperText("")
                        }}
                        InputProps={{
                            endAdornment:
                              <InputAdornment>
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                          }}
                    />
                    <Button onClick={Submit} variant="contained" color="primary">
                        {isLoader ? "Loading..." : "Sign In"}
                    </Button>
                </Box>
            </Box>
            {isErrorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={isErrorMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={successMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
        </Box>
    )
}

export default QMSLogin
