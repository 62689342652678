import React from 'react';
import RouterComponent from './Utility/RouterComponent';
import { createTheme, ThemeProvider, experimental_sx as sx } from '@mui/material/styles';
import { Colors } from './Utility/Colors';
import './App.css'


const App = () => {
  //  Custom Theme and MUI override Rules --- starts
  const CustomTheme = createTheme({

    typography: {
      "fontFamily": `Open Sans`,
    },

    palette: {
      primary: {
        main: Colors.ThemeColor,
        contrastText: Colors.ContrastText
      },
    }
  });
  return (
    <div>
      <ThemeProvider theme={CustomTheme}>
        <RouterComponent />

      </ThemeProvider>
    </div>
  );
};

export default App;