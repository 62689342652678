import { Box, Button, Divider, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { post } from '../../Utility/RestAPIService'
import { ToastMsg } from '../CommonFunctions/CommonFuntions';
import { useSearchParams } from 'react-router-dom';

const AppointmentChange = () => {
    const [status, setStatus] = useState("Reschedule");
    const [date, setDate] = useState("");
    const [reason, setReason] = useState('');
    const [isErrorMsg, setIsErrorMsg] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [isErrorMsgText, setIsErrorMsgText] = useState('');
    const [successMsgText, setsuccessMsgText] = useState('');
    const [searchParams] = useSearchParams();

    // Example: Get a specific query parameter, e.g., "name"
    const tokendata = searchParams.get('token');
    const clinicName = searchParams.get('clinic_name');

    const errorMessage = (message) => {
        setIsErrorMsg(true)
        setIsErrorMsgText(message)
    }

    const successMessage = (message) => {
        setsuccessMsg(true)
        setsuccessMsgText(message)
    }

    const msgClose = () => {
        setIsErrorMsg(false)
        setIsErrorMsgText('')
        setsuccessMsg(false)
        setsuccessMsgText('')
    }

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setDate(selectedDate);
    };

    const handleAddressChange = (event) => {
        const inputName = event.target.value;
        if (inputName.length <= 100) {
            setReason(inputName);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let APPOINTMENT_FORM_POST = 'fo/appointment/patient-change/'
        try {
            if (status === "Reschedule" && date === null) {
                errorMessage("Select date for reschedule")
            } else {
                let data = {
                    "token": tokendata,
                    "status": status,
                    "reason_for_change": reason,
                    "date": date ? date + " 00:00:00" : null
                }
                post(APPOINTMENT_FORM_POST, data)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            successMessage(response.data.message)
                            setStatus("Reschedule")
                            setDate("")
                            setReason("")
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            errorMessage(error.response.data.message)
                        } else {
                            errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            errorMessage(error.message)
        }

    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRight: '1px solid lightgray', padding: '2vw', gap: '1.5vw', width: '100%', height: '100%' }}>

                {clinicName ? <div style={{ backgroundColor: 'lightgray', width: '100%', display: 'flex', justifyContent: 'center', padding: '1vw' }}><Typography variant='h5' sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{clinicName}</Typography></div> : null}
                <h3 style={{ textAlign: 'center', marginTop: '1vh', }}>Your Appointment has been changed !</h3>
                <p style={{
                    textAlign: 'center',
                    color: '#555',
                    fontSize: '1rem',
                    marginTop: '1vh',
                    marginBottom: '2vh',
                }}>Kindly update your status based on availability </p>
                <Divider />
                <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '2vw' }}>
                    <FormControl>
                        <RadioGroup
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value)
                                setReason("")
                                setDate("")
                            }}
                        >
                            <FormControlLabel value="Reschedule" control={<Radio />} label="Reschedule Appointment" />
                            <FormControlLabel value="Transfer" control={<Radio />} label="Transfer Appointment" />
                            <FormControlLabel value="Cancelled" control={<Radio />} label="Cancelled Appointment" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginTop: "1vw" }}>
                        {(status === "Reschedule") ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5vw' }}>
                                <Typography>Select Date of Availability</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <input type='date' value={date} onChange={handleDateChange} data-testid="eMed_dobInput" placeholder='Select Date' min={new Date().toISOString()?.split("T")[0]}
                                        style={{
                                            width: '100%',
                                            padding: '1.5vh',
                                            fontSize: '1rem',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        }} />
                                </Box>
                            </Box>
                            : status === "Cancelled" ?
                                // <textarea value={reason} onChange={handleAddressChange} data-testid="eMed_addressInput" style={{ width: '15vw' }}></textarea>
                                <TextField
                                    label='Enter Reason'
                                    value={reason}
                                    onChange={handleAddressChange}
                                    size='small'
                                />
                                : null}
                    </Box>
                    {/* <input type="submit" value="Submit" data-testid="eMed_submitButton" style={{ marginTop: '1vw' }} /> */}

                </form>
                <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} marginTop={'1.5dvw'}><Button variant='contained' onClick={handleSubmit}>Sumbit</Button></Box>
                <Box position={'absolute'} bottom={'2vh'}><Typography>Developed by <b>Tecnospice Technologies</b></Typography></Box>

            </div>
            {
                isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={isErrorMsgText}
                        msgPop={msgClose.bind(this)}
                    />
                    : null
            }
            {
                successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={successMsgText}
                        msgPop={msgClose.bind(this)}
                    />
                    : null
            }
        </div >
    )
}

export default AppointmentChange