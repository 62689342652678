import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { get } from '../../Utility/RestAPIService'
import { ToastMsg } from '../CommonFunctions/CommonFuntions';
import { useNavigate } from 'react-router-dom';
import { QMS_LINK_LIST } from '../../Utility/Serviceurls';

const QMSLinkList = () => {
    const [linkListData, setLinkListData] = useState([]);
    const [isErrorMsg, setIsErrorMsg] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [isErrorMsgText, setIsErrorMsgText] = useState('');
    const [successMsgText, setsuccessMsgText] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        getLinkListData()
    },[])

    const getLinkListData = () => {
        try {
            get(QMS_LINK_LIST)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response.data;
                        setLinkListData(data.data)
                    }

                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })

        } catch (e) {
            errorMessage(e?.message)
        }
    }

    const errorMessage = (message) => {
        setIsErrorMsg(true)
        setIsErrorMsgText(message)
    }

    const successMessage = (message) => {
        setsuccessMsg(true)
        setsuccessMsgText(message)
    }

    const msgClose = () => {
        setIsErrorMsg(false)
        setIsErrorMsgText('')
        setsuccessMsg(false)
        setsuccessMsgText('')
    }

    const renderTokenScreen = (channelData, clinicName) => {
        let data = {
            channelName: channelData?.chennal_name ? channelData?.chennal_name : '',
            clinicName: clinicName ? clinicName : ''
        }
        navigate("/TokenScreen/LinkList/QMSTokenScreen", { state: data })
    }

    return (
        <div>
            <div style={{ padding: '1vw' }}>
                <Box flex={0.2} display={'flex'} alignItems={'center'}>
                    <Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { navigate(-1) }} >Back to Login</Button>
                </Box>
                {linkListData?.length > 0 ? linkListData?.map((item) => {
                    return (
                        <Box sx={{ padding: '1vw',display:'flex', flexDirection: 'column', gap: '1vw'}}>
                            <Typography fontWeight={'bold'} sx={{ marginTop:'0.5vw', fontSize:'1.2vw', textDecoration:'underline'}}>{item?.branch_name}</Typography>
                            {item?.token_screen_link?.map((list) => {
                                return (
                                    <Box sx={{ marginLeft: '2vw', display:'flex', justifyContent:'space-between', marginTop:'0.5vw' }}>
                                        <Typography >{list?.link}</Typography>
                                        <Box sx={{display:'flex', gap: '1vw'}}>
                                        {list?.doctor_details?.map((element) => {
                                            return (
                                                <Typography>{element?.doctor_name} , </Typography>
                                            )
                                        })}
                                        </Box>
                                        <Button variant='contained' size='small' onClick={() => renderTokenScreen(list, item?.clinic_name)}>Token Screen</Button>
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                }) :
                    <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', marginTop:'6vw'}}>
                        <Typography sx={{ marginTop:'1vw', fontSize:'1.5vw'}}>{`No Token Link were Created.`}</Typography>
                        <Typography sx={{ marginTop:'1vw', fontSize:'1.5vw'}}>{`Create the Token link on Front Office - QMS Configuration`}</Typography>
                    </Box>}
            </div>
            {isErrorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={isErrorMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={successMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
        </div>
    )
}

export default QMSLinkList